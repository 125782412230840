import axios from '../../axios'
import PropTypes from 'prop-types'

const ForgotPasswordQuery = async ({ dispatch, email }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    dispatch({ type: 'set', error: '', message: '', isLoading: true })
    const { data } = await axios.post(
      '/api/user/resetpassword',
      {
        email,
      },
      config,
    )
    dispatch({ type: 'set', isLoading: false, forgotPasswordEmail: '', message: data })
    setTimeout(() => {
      dispatch({ type: 'set', error: '', message: '' })
    }, 5000)
  } catch (err) {
    const errorMessage = err
      ? err.response
        ? 'Error code ' +
          (err.response.status ?? '') +
          ': ' +
          (err.response.data.message && err.response.data.error
            ? err.response.data.message + ' - ' + JSON.stringify(err.response.data.error)
            : err.response.data)
        : err.toString()
      : 'Unknown error'

    dispatch({ type: 'set', isLoading: false, error: errorMessage })
    setTimeout(() => {
      dispatch({ type: 'set', error: '', message: '' })
    }, 5000)
    throw errorMessage
  }
}

ForgotPasswordQuery.propTypes = {
  loginData: PropTypes.object.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default ForgotPasswordQuery
