import PropTypes from 'prop-types'

const LogoutQuery = ({ dispatch }) => {
  sessionStorage.removeItem('authToken')
  sessionStorage.removeItem('currentUser')
  window.location.href = '/login'
  dispatch({
    type: 'set',
    currentUser: null,
    isLoading: false,
    showConfirmStartCampaign: false,
    showConfirmEndCampaign: false,
    uploadKOLCounter: 0,
  })
}

LogoutQuery.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

export default LogoutQuery
