import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route, Switch, useLocation } from 'react-router-dom'
import './scss/style.scss'
import ForgotPassword from './views/pages/forgotPassword'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useDispatch } from 'react-redux'
import ReadDataQuery from './queries/CRUD/ReadDataQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login'))
const Register = React.lazy(() => import('./views/pages/register'))
const Page404 = React.lazy(() => import('./views/pages/page404'))
const Page500 = React.lazy(() => import('./views/pages/page500'))
const AddKOL = React.lazy(() => import('./views/pages/addKOL'))

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)

const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname, search } = useLocation()
  const query = new URLSearchParams(search)
  const tokenInQuery = query.get('token')
  const clientIdInQuery = query.get('client_id')

  React.useEffect(() => {
    if (tokenInQuery && tokenInQuery !== '' && clientIdInQuery && clientIdInQuery !== '') {
      sessionStorage.setItem('authToken', tokenInQuery)
      ReadDataQuery({ dispatch, dbCollectionName: 'client', _id: clientIdInQuery }).then((res) => {
        const fetchedClient = { ...res, level: 'Client' }
        sessionStorage.setItem('currentUser', JSON.stringify(fetchedClient))
        dispatch({ type: 'set', currentUser: JSON.stringify(fetchedClient) })
        history.push(pathname)
      })
    }
  }, [dispatch, tokenInQuery, clientIdInQuery, history, pathname])

  return (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route exact path="/add-kol" name="Add KOL" render={(props) => <AddKOL {...props} />} />
        <Route
          exact
          path="/login"
          name="Login Page"
          render={(props) =>
            !sessionStorage.getItem('authToken') ? <Login {...props} /> : <Redirect to="/" />
          }
        />

        <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) =>
            !sessionStorage.getItem('authToken') ? <Register {...props} /> : <Redirect to="/" />
          }
        />

        <Route
          exact
          path="/forgot-password"
          name="Forgot Password Page"
          render={(props) =>
            !sessionStorage.getItem('authToken') ? (
              <ForgotPassword {...props} />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/"
          name="Home"
          render={(props) =>
            sessionStorage.getItem('authToken') ? (
              <DefaultLayout {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />

        <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
      </Switch>
    </React.Suspense>
  )
}

export default App
