import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,

  isLoading: false,
  showConfirmStartCampaign: false,
  showConfirmEndCampaign: false,
  uploadKOLCounter: 0,
}

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    // Ignore write errors;
  }
}

const persistedState = loadState()

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, persistedState)

store.subscribe(() => {
  saveState(store.getState())
})
export default store
