import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CSpinner,
} from '@coreui/react'
import { Helmet } from 'react-helmet'
import logoVertical from '../../../assets/brand/logo-vertical.png'
import ForgotPasswordQuery from 'src/queries/auth/ForgotPasswordQuery'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const email = useSelector((state) => state.forgotPasswordEmail)
  const isLoading = useSelector((state) => state.isLoading)
  const error = useSelector((state) => state.error)
  const message = useSelector((state) => state.message)

  return (
    <div style={{ backgroundColor: '#FBFBF3' }}>
      <Helmet>
        <title>Login | KOL Listing Virus Media Investara</title>
      </Helmet>

      <div className="min-vh-100 d-flex align-items-center">
        <CContainer>
          <CImage src={logoVertical} height={100} className="mb-4 mx-auto d-block" />

          <CCol>
            <CCard style={{ maxWidth: '500px' }} className="mx-auto d-block">
              <CCardBody className="m-4 p-4">
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault()
                    ForgotPasswordQuery({ dispatch, email })
                  }}
                >
                  <h1 className="text-center">Forgot Password?</h1>
                  <p className="text-medium-emphasis text-center">
                    Enter your registered email below to receive password reset instruction.
                  </p>

                  <CCol className="d-grid gap-3 pt-3 justify-content-center">
                    <CInputGroup className="py-2 mx-auto">
                      <CInputGroupText>@</CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        defaultValue={email}
                        required
                        onChange={(e) =>
                          dispatch({ type: 'set', forgotPasswordEmail: e.target.value })
                        }
                      />
                    </CInputGroup>

                    <CButton color="danger" className="text-white" type="submit">
                      {isLoading ? <CSpinner color="white" size="sm" /> : 'Reset Password'}
                    </CButton>

                    {error && (
                      <CAlert
                        className="my-2"
                        color="danger"
                        dismissible
                        onClose={() => {
                          dispatch({ type: 'set', error: '' })
                        }}
                      >
                        {error}
                      </CAlert>
                    )}

                    {message && (
                      <CAlert
                        className="my-2"
                        color="success"
                        dismissible
                        onClose={() => {
                          dispatch({ type: 'set', message: '' })
                        }}
                      >
                        {message}
                      </CAlert>
                    )}

                    <div className="text-center">
                      <Link to="/login">Back to login.</Link>
                    </div>
                  </CCol>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CContainer>
      </div>
    </div>
  )
}

export default ForgotPassword
