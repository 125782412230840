import axios from '../../axios'
import LogoutQuery from '../auth/LogoutQuery'
import PropTypes from 'prop-types'

const ReadDataQuery = async ({ dispatch, _id, queries, dbCollectionName }) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': sessionStorage.getItem('authToken'),
    },
  }

  try {
    dispatch({ type: 'set', isLoading: true })

    const url = `/api/${dbCollectionName}${
      queries
        ? `?` +
          `${Object.entries(queries)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`
        : ''
    }`

    const { data } = _id
      ? await axios.get(`/api/${dbCollectionName}/${_id}`, config)
      : await axios.get(url, config)

    if (Array.isArray(data.data)) {
      data.data.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (
            key.toLowerCase().includes('password') ||
            key.toLowerCase() === '__v' ||
            key.toLowerCase() === 'deleted'
          ) {
            delete item[key]
          }
        })
      })
    } else if (
      data.data &&
      typeof data.data === 'object' &&
      !Array.isArray(data.data) &&
      data.data !== null
    ) {
      Object.entries(data.data).forEach(([key, value]) => {
        if (
          key.toLowerCase().includes('password') ||
          key.toLowerCase() === '__v' ||
          key.toLowerCase() === 'deleted'
        ) {
          delete data.data[key]
        }
      })
    }

    dispatch({ type: 'set', isLoading: false })
    return data
  } catch (err) {
    const errorMessage = err
      ? err.response
        ? 'Error code ' +
          (err.response.status ?? '') +
          ': ' +
          (err.response.data.message && err.response.data.error
            ? err.response.data.message + ' - ' + JSON.stringify(err.response.data.error)
            : err.response.data)
        : err.toString()
      : 'Unknown error'

    dispatch({ type: 'set', isLoading: false, error: errorMessage })
    setTimeout(() => dispatch({ type: 'set', error: '', message: '' }), 5000)

    if (err.response && (err.response.status === 403 || err.response.status === 401)) {
      LogoutQuery({ dispatch })
    }
    throw errorMessage
  }
}

ReadDataQuery.propTypes = {
  _id: PropTypes.string,
  queries: PropTypes.object,
  dbCollectionName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
}
export default ReadDataQuery
